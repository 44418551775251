import React, { useRef, useEffect, useState }  from 'react'
import Link from 'gatsby-link'
import ReactMarkdown from 'react-markdown'
import { FaEthereum, FaTwitter } from 'react-icons/fa';
import { GoUnmute, GoMute } from 'react-icons/go';
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from '../components/layout'
import SEO from '../components/seo'
import PlanetTags from "../components/planet-tags"

const AssetPage = ({ pageContext: { previous, next }, data: { asset, image } }) => {
  const [isMuted, setMuted] = useState(true);
  const refVideo = useRef(null);
  const customMeta = [{
    name: `twitter:image`,
    content: `https://www.mypixelplanet.com${image.childImageSharp.fixed.src}`,
  }];


  const toggleMute = () => {
    if (!refVideo.current) return;

    refVideo.current.defaultMuted = !isMuted;
    refVideo.current.muted = !isMuted;
    setMuted(muted => !muted)
  }

  return (
    <Layout title={asset.name}>
        <SEO title={asset.name} description={asset.description} meta={customMeta} />

        <div className="planet-wrapper">
          <div className="subtitle is-font-pixel has-text-primary has-text-centered mt-3" style={{ fontSize: "0.85rem" }}>
            LIFEFORMS: {asset.lifeforms}
            <br className="my-2" />
            TEMPERATURE: {asset.temperature}
          </div>

          <nav className="pagination is-centered my-6" role="navigation" aria-label="pagination">
            <Link to={`/${previous}/`} className="pagination-previous button is-outlined is-primary" title="Previous Planet" style={{ visibility: !previous ? 'hidden' : 'visible' }}>&lt;</Link>
            <Link to={`/${next}/`} className="pagination-next button is-outlined is-primary" title="Next Planet" style={{ visibility: !next ? 'hidden' : 'visible' }}>&gt;</Link>

            <ul className="pagination-list">
              <li><OutboundLink href={asset.opensea} target="_blank" rel="noreferrer" className="button is-primary is-outlined is-rounded has-text-weight-bolder is-responsive-small mx-2"><FaEthereum className="mr-2" /> View on OpenSea</OutboundLink></li>
              <li><a rel="noreferrer" href={`https://twitter.com/intent/tweet?text=Planet%20${asset.name}%3A%20${asset.description}%20%23MyPixelPlanet&url=https://www.mypixelplanet.com/${asset.id}/`} className="button is-link is-outlined is-rounded has-text-weight-bolder  is-responsive-small" target="_blank"><FaTwitter className="mr-2"/> Share</a></li>
            </ul>
          </nav>

          <div className="planet-player">
            <figure className="image" style={{ position: "relative" }}>

              <video ref={refVideo} autoPlay muted loop playsInline className={`has-ratio ${asset.legendary && "legendary"} ${asset.rare && "rare"} ${asset.epic && "epic"}`} width="620" height="420" poster={image.childImageSharp.fixed.src}>
                  <source src={`/${asset.id}.mp4`} type="video/mp4" />
              </video>

              <PlanetTags asset={asset} isBig={true} />
            </figure>

            <button className="button is-dark is-outlined" onClick={() => toggleMute()}>
              {!isMuted && <GoUnmute style={{ color: 'white' }} />}
              {isMuted && <GoMute style={{ color: 'white' }} />}
            </button>
          </div>

          <h2 className="title is-size-3 is-size-4-mobile mt-5 has-text-white has-text-weight-bolder has-text-centered">
            <ReactMarkdown>
              {asset.description}
            </ReactMarkdown>
          </h2>

          {asset.forSale && <OutboundLink href={asset.opensea} target="_blank" rel="noreferrer" className="mt-3 button is-primary is-responsive-large is-rounded has-text-weight-bolder"><FaEthereum className="mr-2" /> Buy on OpenSea for Ξ{asset.price}</OutboundLink> }
          {!asset.forSale && <button className="button is-responsive is-outlined is-disabled is-rounded is-italic is-inverted" disabled>Currently not for sale</button> }
        </div>
    </Layout>
  )
}
  
export default AssetPage

export const planetFragment = graphql`
  fragment PlanetFragment on Asset {
    id
    name
    description
    opensea
    lifeforms
    edition
    temperature
    rare
    epic
    legendary
    forSale
    price
    owner
    ownerAvatar
  }
`

export const query = graphql`
  query Asset($id: String!) {
    asset(id: { eq: $id }) {
      ... PlanetFragment
    }
    image: file(name: { eq: $id }) {
      childImageSharp {
        fixed(width: 620) {
          ... GatsbyImageSharpFixed
        }
      }
    }
  }
`